import React, { useCallback } from "react";
import { TFunction } from "react-i18next";
import Router from "next/router";
import { withTranslation } from "next-i18next";

import PageNotFound from "@sellernote/shared/src/sds-v1/components/error/PageNotFound";

import Layout from "../../Layout";
import Seo from "../../Seo";

function PageNotFoundPage({ t }: { t: TFunction }) {
  const goBackToMainPage = useCallback(() => Router.push("/"), []);

  return (
    <Layout backgroundColor="#fafafa">
      <Seo path="/" />
      <PageNotFound t={t} goBackToMainPage={goBackToMainPage} />
    </Layout>
  );
}

export default withTranslation()(PageNotFoundPage);
